import { render, staticRenderFns } from "./travelBillDetail.vue?vue&type=template&id=db099f04&scoped=true&"
import script from "./travelBillDetail.vue?vue&type=script&lang=js&"
export * from "./travelBillDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db099f04",
  null
  
)

export default component.exports